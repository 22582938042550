import { doFetch } from "utils/api/utils";
import { IUser } from "utils/model/User";

export async function fetchAuthcheck(): Promise<IUser>
{
  window.localStorage.setItem(
    "last-authcheck",
    new Date().getTime().toString()
  );
  return await doFetch("/api/users/authcheck");
}
