import { doFetch, doFetchDownload } from "utils/api/utils";
import { info } from "utils/function/console";

export async function fetchResultsCSV(surveyId: string)
{
  return await doFetchDownload(
    `/api/answers/resultsCsv?warmup=false&surveyId=${surveyId}`
  );
}
export async function fetchSummary(
  surveyId: string,
  threshold: number,
  normalizedTime: boolean,
  warmup = false
)
{
  return await doFetch(
    `/api/answers/summary?surveyId=${surveyId}&threshold=${threshold}&normalizedTime=${normalizedTime}&warmup=${warmup}`
  );
}

export async function fetchAnswersCsv()
{
  return await doFetch("/api/answers/resultsCsv"
  );
}

// export async function fetchAnswers() {
//   return await doFetch("/answers");
// }

// export async function createAnswers() {
//   // check this name
//   return await doFetch("/answers", {
//     method: "post",
//   });
// }
