import { HTTPError } from "utils/error/http";
import { log } from "utils/function/console";
import { saveAs } from "file-saver";

export function getURL(route: string)
{
  return process.env.REACT_APP_BACKEND_URL + route;
}

export async function doFetch(route: string, data?: RequestInit)
{
  // if (process.env.NODE_ENV === "test") {

  // }

  const res = await fetch(getURL(route), {
    ...(data || {}),
    headers: {
      ...(data?.headers || {}),
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  });

  const length = res.headers.get("Content-Length");
  if (length === null || length === "0")
  {
    return true;
  }

  const resdata = await res.json();
  if (res.status >= 400)
  {
    // FIXME this should be in format of HTTPError
    // and HttpException should take this data
    throw resdata;
  }

  return resdata;
}

export async function doFetchDownload(route: string, data?: RequestInit)
{
  const res = await fetch(getURL(route), {
    ...(data || {}),
    headers: {
      ...(data?.headers || {}),
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  });

  saveAs(await res.blob(), "export.csv");

  if (res.status >= 400)
  {
    // FIXME this should be in format of HTTPError
    // and HttpException should take this data
    throw res;
  }

  return res.blob;
}
